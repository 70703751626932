@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @layer utilities {
  // Hide scrollbar for Chrome, Safari and Opera
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 // Hide scrollbar for IE, Edge and Firefox 
  .no-scrollbar {
      -ms-overflow-style: none;  
      scrollbar-width: none;  
}
} */

/*
4px
8px
12px
16px
24px
32px
48px
64px
96px
128px
192px
256px
384px
512px
640px
768px
*/

.box-bg {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
}

.top-line {
  display: flex;
  justify-content: space-between;
}

#picture {
  border: solid 1px #e8b777;
  width: 160px;
  height: 200px;
  margin: 15px;
}

.stat-list {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 600px;
}

.main-stat-group {
  min-width: 130px;
  display: flex;
  flex: 1 1;
  justify-content: center;
}

.main-stat-item {
  display: flex;
  flex-direction: column;
}

.main-stat-item-label {
  font-family: Roboto Condensed,Roboto,Helvetica,sans-serif;
  font-weight: 700;
  height: 1em;
  margin-bottom: 6px;
  text-transform: uppercase;
  color: #b0b7bd;
}

.main-stat-item-content {
  font-size: 26px;
  font-weight: 500;
  position: relative;
}

.main-stat-number {
  color: #fff;
}

.main-stat-sep {
  color: #d8d8d8;
  padding: 0 10px;
}

#charac-box, #skill-box {
  border: solid 1px #e8b777;
  margin: 15px;
}

#charac-box-title, #skill-box-title {
  text-align: center;
}

.charac-list {
  display: flex;
  padding: 25px;
  align-items: center;
  justify-content: space-between
}

.skill-list {
  display: flex;
  flex-flow: column wrap;
  height: 765px;
  align-items: center;
}

.stat-block, .stat-value-block {
  display: flex;
  flex-direction: row;
  margin: 2px;
}

.stat-name {
  width: 120px;
  margin: auto 0;
  padding-right: 12px;
}

#charac-box .stat-name {
  width: 45px;
}

.stat-value-main {
  border: solid 2px #e8b777;
  padding: 5px 15px;
  font-size: 24px;
  width: 60px;
  text-align: center;
}

.stat-value-secondary {
  display: flex;
  flex-direction: column;
}

.stat-value-hard, .stat-value-extreme {
  border-right: solid 2px #e8b777;
  border-bottom: solid 2px #e8b777;
  padding: 2px 0px;
  font-size: 13px;
  width: 32px;
  text-align: center;
}

.stat-value-hard {
  border-top: solid 2px #e8b777;
  border-top-right-radius: 10px;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
